<script setup lang="ts">
import { useOnline } from '@vueuse/core';

const route = useRoute();
const { user, isLoading }  = useUserStore();

const isOnline = useOnline();
const { dispatchMessage } = useSystemMessages();

const showVerifyYourAccountModal = ref(false);

const showFlagManager = computed(() => {
  return route.query.flags !== undefined;
});

watch(isOnline, (newValue: boolean) => {
  if (newValue) {
    dispatchMessage('Back online.', 'success', 'Your browser is back online.', 10000);
    return;
  }

  dispatchMessage('You are offline.', 'warn', 'Your browser went offline, please check your internet connection.', 15000);
});

watch(isLoading, newValue => {
  if (newValue === true) {
    return;
  }

  if (user && user.value?.isVerified === false) {
    showVerifyYourAccountModal.value = true;
  }
});
</script>

<template>
  <div class="layout-empty">
    <SystemMessages />

    <slot />

    <UserVerifyYourAccountModal v-if="showVerifyYourAccountModal" />

    <FeatureFlagManager v-if="showFlagManager" />
  </div>
</template>

<style lang="scss">
.layout-empty {
  height: 100%;

  .system-messages {
    position: fixed;
    top: 5em;
    left: var(--component-margin);
    right: var(--component-margin);
    margin: 0 auto;
    z-index: 99000;
  }
}
</style>